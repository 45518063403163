import React from 'react'

declare module 'react' {
    interface TableHTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        // extends React's HTMLAttributes
        width?: string | number
        border?: string
    }
    interface TdHTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        // extends React's HTMLAttributes
        width?: string | number
        nowrap?: string
    }
    interface AnchorHTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        // extends React's HTMLAttributes
        nosend?: string
    }
}
interface SignatureProps {
    name?: string
    title?: string
    email?: string
    phone?: string
    mobile?: string
}

const Signature = ({ name, title, email, phone, mobile }: SignatureProps) => {
    return (
        <div className="signature">
            <div id="generated-signature">
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html: `
                    @media screen and (max-width: 480px) {
                                .column {
                                    display:block !important;
                                    width:100% !important;
                                }
                                .responsive-table {
                                    width:100% !important;
                                }
                                .column-spacer {
                                    padding:0 !important;
                                }
                            }
                    `,
                    }}
                />
                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <td
                                valign="top"
                                className="column column-top"
                                width="109"
                                style={{
                                    verticalAlign: 'top',
                                    paddingRight: '24px',
                                    float: 'left',
                                }}
                            >
                                <table
                                    width="85"
                                    className="main responsive-table"
                                    cellPadding="0"
                                    cellSpacing="0"
                                    style={{
                                        boxSizing: 'border-box',
                                        borderCollapse: 'collapse',
                                        color: '#1B1B1B',
                                        padding: '20px 0',
                                        paddingBottom: '60px',
                                        width: '85px',
                                        maxWidth: '100%',
                                    }}
                                >
                                    <tbody>
                                        <tr style={{ padding: '0' }}>
                                            <td
                                                valign="top"
                                                className="column1 img-column"
                                                style={{
                                                    verticalAlign: 'top',
                                                    height: '43px',
                                                    marginTop: '2px',
                                                    paddingBottom: ' 20px',
                                                    width: '85px',
                                                }}
                                            >
                                                <img
                                                    alt="(i.e.,)"
                                                    src="https://signature.ie.com.au/img/ie-logo.gif"
                                                    className="logo"
                                                    width="85"
                                                    style={{
                                                        verticalAlign: 'bottom',
                                                        width: '85px',
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td
                                valign="top"
                                className="column"
                                width="441"
                                style={{ verticalAlign: 'top', float: 'left' }}
                            >
                                <table
                                    width="441"
                                    className="main responsive-table"
                                    cellPadding="0"
                                    cellSpacing="0"
                                    style={{
                                        boxSizing: 'border-box',
                                        borderCollapse: 'collapse',
                                        color: '#1B1B1B',
                                        padding: '20px 0',
                                        paddingBottom: '60px',
                                        width: '441px',
                                        maxWidth: '100%',
                                    }}
                                >
                                    <tbody>
                                        <tr style={{ padding: '0' }}>
                                            <td
                                                className="column2"
                                                nowrap="nowrap"
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    maxWidth: '600px',
                                                    fontSize: '15px',
                                                    lineHeight: 1,
                                                    verticalAlign: 'bottom',
                                                    width: '100%',
                                                    padding: '0 0 5px',
                                                }}
                                            >
                                                <h2
                                                    className="name"
                                                    style={{
                                                        color: '#1B1B1B',
                                                        display: 'inline-block',
                                                        fontFamily:
                                                            'Arial, Helvetica, sans-serif,serif',
                                                        fontWeight: 700,
                                                        fontSize: '15px',
                                                        lineHeight: 1,
                                                        margin: '0',
                                                    }}
                                                >
                                                    {name}
                                                </h2>
                                                &nbsp;|&nbsp;
                                                <h3
                                                    className="title"
                                                    style={{
                                                        fontFamily:
                                                            'Arial, Helvetica, sans-serif,serif',
                                                        fontSize: '15px',
                                                        fontWeight: 400,
                                                        display: 'inline-block',
                                                        lineHeight: 1,
                                                        margin: 0,
                                                    }}
                                                >
                                                    {title}
                                                </h3>
                                            </td>
                                        </tr>
                                        <tr style={{ padding: 0 }}>
                                            <td
                                                className="column2"
                                                style={{
                                                    verticalAlign: 'bottom',
                                                    width: '100%',
                                                }}
                                            >
                                                <table
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                >
                                                    <tbody>
                                                        <tr
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <td
                                                                valign="middle"
                                                                nowrap="nowrap"
                                                                className="paragraph-cell colour-cell"
                                                                style={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    color:
                                                                        '#1B1B1B',
                                                                    fontFamily:
                                                                        'Arial,sans-serif',
                                                                    fontSize:
                                                                        '12px',
                                                                    padding:
                                                                        '5px 4px 5px 0',
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            '#1B1B1B',
                                                                    }}
                                                                >
                                                                    <b>e:</b>
                                                                    &nbsp;
                                                                </span>
                                                                <a
                                                                    className="link"
                                                                    href={`mailto: ${email}`}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    title="office"
                                                                    style={{
                                                                        color:
                                                                            '#1B1B1B',
                                                                        height:
                                                                            '12px',
                                                                        textDecoration:
                                                                            'none !important',
                                                                    }}
                                                                >
                                                                    {email}
                                                                </a>
                                                                &nbsp;|&nbsp;
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            '#1B1B1B',
                                                                    }}
                                                                >
                                                                    <b>w:</b>
                                                                    &nbsp;
                                                                </span>
                                                                <a
                                                                    className="link"
                                                                    href="https://www.ie.com.au"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    title="ie.com.au"
                                                                    style={{
                                                                        color:
                                                                            '#1B1B1B',
                                                                        height:
                                                                            '12px',
                                                                        textDecoration:
                                                                            'none !important',
                                                                    }}
                                                                >
                                                                    ie.com.au
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <td
                                                                valign="middle"
                                                                nowrap="nowrap"
                                                                className="paragraph-cell colour-cell"
                                                                style={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    color:
                                                                        '#1B1B1B',
                                                                    fontFamily:
                                                                        'Arial,sans-serif',
                                                                    fontSize:
                                                                        '12px',
                                                                    padding:
                                                                        '5px 4px 5px 0',
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            '#1B1B1B',
                                                                    }}
                                                                >
                                                                    <b>p:</b>{' '}
                                                                </span>
                                                                <a
                                                                    className="link"
                                                                    href={`tel:${phone}`}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    title="office"
                                                                    style={{
                                                                        color:
                                                                            '#1B1B1B',
                                                                        height:
                                                                            '12px',
                                                                        textDecoration:
                                                                            'none !important',
                                                                    }}
                                                                >
                                                                    {phone}
                                                                </a>
                                                                &nbsp;|&nbsp;
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            '#1B1B1B',
                                                                    }}
                                                                >
                                                                    <b>m:</b>{' '}
                                                                </span>
                                                                <a
                                                                    className="link"
                                                                    href={`tel: ${mobile}`}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    title="mobile"
                                                                    style={{
                                                                        color:
                                                                            '#1B1B1B',
                                                                        height:
                                                                            '12px',
                                                                        textDecoration:
                                                                            'none !important',
                                                                    }}
                                                                >
                                                                    {mobile}
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                >
                                                    <tbody>
                                                        <tr
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <td
                                                                className="colour-cell"
                                                                style={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    color:
                                                                        '#1B1B1B',
                                                                    fontFamily:
                                                                        'Arial,sans-serif',
                                                                    fontSize:
                                                                        '12px',
                                                                    padding:
                                                                        '5px 0 5px',
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            '#1B1B1B',
                                                                    }}
                                                                >
                                                                    Level 1/
                                                                    41-43 Stewart St, 
                                                                    Richmond VIC 3121
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr style={{ padding: 0 }}>
                                            <td
                                                className="column2"
                                                style={{
                                                    padding: '5px 0',
                                                    verticalAlign: 'bottom',
                                                    width: '100%',
                                                }}
                                            >
                                                <table
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    <tbody>
                                                        <tr
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <td
                                                                nowrap="nowrap"
                                                                className="image-cell"
                                                                style={{
                                                                    paddingRight:
                                                                        '12px',
                                                                    float:
                                                                        'left',
                                                                }}
                                                            >
                                                                <a
                                                                    className="social-link"
                                                                    href="https://www.facebook.com/iecompanyau/"
                                                                    nosend="1"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    title="Facebook"
                                                                    style={{
                                                                        border:
                                                                            'none',
                                                                        borderStyle:
                                                                            'none',
                                                                        height:
                                                                            '12px',
                                                                        width:
                                                                            '13px',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src="https://signature.ie.com.au/img/facebook.png"
                                                                        alt="Facebook"
                                                                        className="social-icon"
                                                                        height="12"
                                                                        style={{
                                                                            border: 0,
                                                                            height:
                                                                                '12px',
                                                                        }}
                                                                    />
                                                                </a>
                                                            </td>
                                                            <td
                                                                nowrap="nowrap"
                                                                className="image-cell"
                                                                style={{
                                                                    paddingRight:
                                                                        '12px',
                                                                    float:
                                                                        'left',
                                                                }}
                                                            >
                                                                <a
                                                                    className="social-link"
                                                                    href="https://twitter.com/iecompanyau"
                                                                    nosend="1"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    title="Twitter"
                                                                    style={{
                                                                        border:
                                                                            'none',
                                                                        borderStyle:
                                                                            'none',
                                                                        height:
                                                                            '12px',
                                                                        width:
                                                                            '13px',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src="https://signature.ie.com.au/img/twitter.png"
                                                                        alt="Twitter"
                                                                        className="social-icon"
                                                                        height="12"
                                                                        style={{
                                                                            border: 0,
                                                                            height:
                                                                                '12px',
                                                                        }}
                                                                    />
                                                                </a>
                                                            </td>
                                                            <td
                                                                nowrap="nowrap"
                                                                className="image-cell"
                                                                style={{
                                                                    paddingRight:
                                                                        '12px',
                                                                    float:
                                                                        'left',
                                                                }}
                                                            >
                                                                <a
                                                                    className="social-link"
                                                                    href="https://instagram.com/iecompanyau"
                                                                    nosend="1"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    title="Instagram"
                                                                    style={{
                                                                        border:
                                                                            'none',
                                                                        borderStyle:
                                                                            ' none',
                                                                        height:
                                                                            '11px',
                                                                        width:
                                                                            '13px',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src="https://signature.ie.com.au/img/instagram.png"
                                                                        alt="Instagram"
                                                                        className="social-icon"
                                                                        height="11"
                                                                        style={{
                                                                            border: 0,
                                                                            height:
                                                                                '11px',
                                                                        }}
                                                                    />
                                                                </a>
                                                            </td>
                                                            <td
                                                                nowrap="nowrap"
                                                                className="image-cell"
                                                                style={{
                                                                    paddingRight:
                                                                        '12px',
                                                                    float:
                                                                        'left',
                                                                }}
                                                            >
                                                                <a
                                                                    className="social-link"
                                                                    href="https://www.linkedin.com/company/ie-company/"
                                                                    nosend="1"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    title="LinkedIn"
                                                                    style={{
                                                                        border:
                                                                            'none',
                                                                        borderStyle:
                                                                            'none',
                                                                        height:
                                                                            '12px',
                                                                        width:
                                                                            '13px',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src="https://signature.ie.com.au/img/linkedin.png"
                                                                        alt="LinkedIn"
                                                                        className="social-icon"
                                                                        height="12"
                                                                        style={{
                                                                            border: 0,
                                                                            height:
                                                                                '12px',
                                                                        }}
                                                                    />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr style={{ padding: 0 }}>
                                            <td>
                                                <table
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                className="column2"
                                                                style={{
                                                                    padding:
                                                                        '5px 0',
                                                                    verticalAlign:
                                                                        'bottom',
                                                                }}
                                                            >
                                                                <img
                                                                    src="https://signature.ie.com.au/img/panel.gif"
                                                                    alt="-"
                                                                    style={{
                                                                        maxWidth:
                                                                            '264px',
                                                                    }}
                                                                />
                                                                <img
                                                                    src="https://signature.ie.com.au/img/panel.png"
                                                                    alt="-"
                                                                    style={{
                                                                        maxWidth:
                                                                            '264px',
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default Signature
